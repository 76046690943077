.reader-warning-container {
	background-color: #fffbcc;

	.reader-warning-alert {
		color: red;
		font-size: 16px;
		font-weight: 600;
	}

	.reader-warning-message {
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5;
		color: #212529;
		text-align: left;
	}
}
